export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBowwOT5MzlFI6su7euxu6zIDUmbTSrf1Q",
    authDomain: "plats-bruts-joc.firebaseapp.com",
    databaseURL: "https://plats-bruts-joc.firebaseio.com",
    projectId: "plats-bruts-joc",
    storageBucket: "plats-bruts-joc.appspot.com",
    messagingSenderId: "309137573051",
    appId: "1:309137573051:web:d12fa105c1f4818fe0d59e",
    measurementId: "G-EGTKB7RMT6"
  }
};